<template>
  <div class="contact">
    <p class="title">Credits</p>
    <div class="cards">
      <div class="card" v-for="info in infos" :key="info.name">
        <ul>
          <li class="card-item">
            <el-icon class="icon"><user /></el-icon>
            <p class="key">Name:</p>
            <p class="value">{{ info.name }}</p>
          </li>
          <li class="card-item">
            <el-icon class="icon"><school /></el-icon>
            <p class="key">Institution:</p>
            <p class="value">{{ info.institution }}</p>
          </li>
          <li class="card-item">
            <el-icon class="icon"><location /></el-icon>
            <p class="key">Address:</p>
            <p class="value" v-html="info.address"></p>
          </li>
          <li class="card-item">
            <el-icon class="icon"><message /></el-icon>
            <p class="key">E-mail:</p>
            <p class="value">{{ info.email }}</p>
          </li>
        </ul>
      </div>
    </div>
    <p class="foot">
      DeepCancerMap is developed and maintained by idrugLab at South China
      University of Technology. If you have any questions or suggestions, please
      contact us by above contact details.
    </p>
  </div>
</template>

<script>
import data from "../api/data/contact_info.json";

export default {
  name: "contact",
  data() {
    return {
      infos: data,
    };
  },
  created() {
    console.log(this.infos);
  },
};
</script>

<style scoped>
.contact {
  padding: 60px 0;
  /* background-image: linear-gradient(var(--color1-dark), var(--color1-light)); */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.cards {
  width: 70%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 30px;
  margin: 20px 0;
}

.card {
  box-sizing: border-box;
  width: fit-content;
  padding: 10px;
  background: linear-gradient(to left, var(--color2-light), var(--color2-dark));
  color: var(--color1-dark);
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
}

.card ul {
  list-style-type: none;
}

.card-item {
  display: flex;
  margin: 8px 0;
  font-size: 15pt;
}

.key {
  width: 110px;
  /* background: red; */
  white-space: nowrap;
}

.value {
  flex: 1;
  font-weight: bold;
}

.title {
  color: white;
  text-align: center;
  font-size: 30pt;
  margin-top: 20px;
}

.foot {
  color: #ccc;
}
</style>
